<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import { getNode } from '@formkit/core'
const shippingSideBar = SIDEBAR_IDS.cartShippingSidebar

const formData = ref({
  'zip-code': '',
})

const { ts } = useI18n()
const { isPostalCodeValid: checkPostalCode } = useGoogleMaps()
const { country } = useRouteHelper()
const { marketShippingMethods } = useShippingMethods()

const deliveryInformation = ref<DeliveryInformation[]>([])

const handleSubmit = async () => {
  const isPostalCodeValid = await checkPostalCode(
    country,
    formData.value['zip-code']
  )
  if (isPostalCodeValid) {
    // TODO: (tracked #1134) filter by postal code
    deliveryInformation.value = marketShippingMethods.value
  } else {
    getNode('zipCodeInput')?.setErrors(ts('cartPage.recap.invalidZipCode'))
    deliveryInformation.value = []
  }
}
const { isDialogOpen } = useDialog(shippingSideBar)

const { data: warehouseBanner, execute } = await useExtraInfoItem(
  'shipping-costs-and-timelines'
)
watch(isDialogOpen, async value => {
  if (value) {
    await execute()
  }
})
</script>

<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<OrganismsSidebarSlide
      :id="shippingSideBar"
      :header-props="{
        titleText: $ts('cartPage.recap.sideSheet.shipping.title'),
      }"
    >
      <template #body>
        <div class="gap-y-lg md:gap-y-xl flex flex-col">
          <UtilsMarkdown
            class="text-light-6"
            :content="$ts('cartPage.recap.sideSheet.shipping.enterYourZipCode')"
            container="p"
          />

          <FormKit
            v-model="formData"
            type="form"
            :actions="false"
            :incomplete-message="false"
            @submit="handleSubmit"
          >
            <InputsTextFields
              id="zipCodeInput"
              type="text"
              :label="$ts('cartPage.recap.zipCodeLabel')"
              name="zip-code"
              :validation="'number|+length:1'"
              :show-confirm-button="false"
              :autocomplete="'postal-code'"
            />
          </FormKit>

          <div v-if="deliveryInformation?.length">
            <div class="gap-y-lg flex flex-col">
              <div
                v-for="delivery in deliveryInformation"
                :key="delivery.id"
                class="flex flex-col gap-1"
              >
                <div class="flex justify-between">
                  <p class="text-book-6" v-text="delivery.type" />
                  <p class="text-light-6" v-text="delivery.cost" />
                </div>
                <p class="text-light-6" v-text="delivery.description" />
              </div>
            </div>
          </div>

          <p
            v-if="deliveryInformation?.length"
            class="text-light-6"
            v-text="$ts('cartPage.recap.sideSheet.shipping.shippingOptions')"
          />

          <OrganismsMessageLong
            v-if="warehouseBanner?.content?.body"
            :message="warehouseBanner?.content?.body"
          />
        </div>
      </template>
    </OrganismsSidebarSlide>
</FormKitLazyProvider>

  </div>
</template>
