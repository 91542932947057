type MapsResponse = {
  results: []
  status: 'ZERO_RESULTS' | 'OK'
}

export const useGoogleMaps = () => {
  const googleMapsKey = useRuntimeConfig().public.google

  const isPostalCodeValid = async (
    country: string = '',
    postalCode: string = ''
  ) => {
    if (!postalCode || !country) return false

    const data = await $fetch<MapsResponse>(
      'https://maps.googleapis.com/maps/api/geocode/json',
      {
        method: 'GET',
        params: {
          key: googleMapsKey,
          components: `country:${country}|postal_code:${postalCode}`,
        },
      }
    )

    if (!data || data.status === 'ZERO_RESULTS' || data.results.length === 0) {
      return false
    }

    return true
  }

  return { isPostalCodeValid }
}
